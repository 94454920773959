import { Outlet } from "react-router-dom";
import ScrollTop from "../utils/ScrollTop";

export function Main() {
  return (
    <>
      <ScrollTop>
        <Outlet />
      </ScrollTop>
    </>
  );
}

import { useState } from "react";
import "./HorizontalRangeSlider.css";
export function HorizontalRangeSlider({
  max,
  min = 1,
  value,
  setValue,
  step = 1,
}) {
  const [isRangeTouch, setIsRangeTouch] = useState(false);
  const calculatePercentage = () => {
    const percentage = (value / max) * 100;
    return parseInt(percentage);
  };
  return (
    <>
      <input
        className="horizontal-range"
        type="range"
        name=""
        value={value}
        min={min}
        max={max}
        step={isRangeTouch ? step : 1}
        required
        onChange={(e) => setValue(Number(e.target.value))}
        onTouchStart={() => setIsRangeTouch(true)}
        style={{
          background: `linear-gradient(to right, #6765e8 0%, #6765e8 ${calculatePercentage()}%, #c3d8f5 ${calculatePercentage()}%, #c3d8f5 100%)`,
        }}
      />
    </>
  );
}

import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export function BackNavigation({ link, stepAction, setState, state }) {
  const navigate = useNavigate();
  return (
    <>
      <span
        onClick={() => {
          link && navigate(link);
          stepAction && stepAction((prev) => prev - 1);
          setState && setState(state);
        }}
        className="absolute top-[22px] left-5 z-50 h-[30px] w-[30px] cursor-pointer"
      >
        <AiOutlineLeft className="h-6 w-6" />
      </span>
    </>
  );
}

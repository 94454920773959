import React from "react";
import calenderImg from "../../Assets/calender.png";
export default function LeavesInfo({ metaData }) {
  return (
    <div className="w-full px-5 pt-7">
      <div className="flex justify-between items-center">
        <div>
          <h5 className="text-lg text-grayText font-semibold capitalize">
            PAID LEAVES
          </h5>
          <strong className="text-4xl">
            {metaData?.employeeProfile?.paidLeaveBalance}
          </strong>
          {metaData?.employeeProfile?.id === 1 ? (
            <div>
              worth <strong className="text-gray-700">₹59,323</strong>
            </div>
          ) : undefined}
        </div>
        <div className="pr-5">
          <div className="w-32 h-32">
            <img src={calenderImg} alt="calender" className="object-contain" />
          </div>
        </div>
      </div>
    </div>
  );
}

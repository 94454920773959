import React, { Suspense } from "react";
import { ErrorPage } from "../../Pages/ErrorPage/ErrorPage";
import Loader from "../Loader/Loader";
const LoanSalaryIssue = React.lazy(() =>
  import("./LoanSalaryIssue/LoanSalaryIssue")
);
const LoanProcessing = React.lazy(() =>
  import("./LoanProcessing/LoanProcessing")
);
const LoanApproved = React.lazy(() => import("./LoanApproved/LoanApproved"));
const LoanRejected = React.lazy(() => import("./LoanRejected/LoanRejected"));
const LoanOngoing = React.lazy(() => import("./LoanOngoing/LoanOngoing"));
const LoanCompleted = React.lazy(() => import("./LoanCompleted/LoanCompleted"));
const LoanDisbursal = React.lazy(() => import("./LoanDisbursal/LoanDisbursal"));

export function LoanStatus({ loanStatus }) {
  let content;
  switch (loanStatus) {
    case "processing":
      content = (
        <Suspense fallback={<Loader height="full" message="" />}>
          <LoanProcessing />
        </Suspense>
      );
      break;
    case "approved":
      content = (
        <Suspense fallback={<Loader height="full" message="" />}>
          <LoanApproved />
        </Suspense>
      );
      break;
    case "incorrect-salary":
      content = (
        <Suspense fallback={<Loader height="full" message="" />}>
          <LoanSalaryIssue />
        </Suspense>
      );
      break;
    case "rejected":
      content = (
        <Suspense fallback={<Loader height="full" message="" />}>
          <LoanRejected />
        </Suspense>
      );
      break;
    case "disbursal":
      content = (
        <Suspense fallback={<Loader height="full" message="" />}>
          <LoanDisbursal />
        </Suspense>
      );
      break;
    case "ongoing":
      content = (
        <Suspense fallback={<Loader height="full" message="" />}>
          <LoanOngoing />
        </Suspense>
      );
      break;
    case "completed":
      content = (
        <Suspense fallback={<Loader height="full" message="" />}>
          <LoanCompleted />
        </Suspense>
      );
      break;
    default:
      content = <ErrorPage />;
      break;
  }
  return <div className="px-5">{content}</div>;
}

import blazeIcon from "../../Assets/blaze-fast-icon.png";
import calenderIcon from "../../Assets/calender-icon.png";
import secureIcon from "../../Assets/secure-icon.png";
import shakeHandIcon from "../../Assets/shake-hand-icon.png";

const benefits = [
  {
    icon: blazeIcon,
    title: "blazing fast",
    description:
      "One tap KYC. Auto-filled forms, fetched from your employee directory.",
  },
  {
    icon: calenderIcon,
    title: "reduce interest",
    description: "Use leaves to reduce your interest rates.",
  },
  {
    icon: secureIcon,
    title: "secure & encrypted",
    description:
      "We don’t save your data. We fetch it from your employer when you apply for a loan.",
  },
  {
    icon: shakeHandIcon,
    title: "no hidden charges",
    description: "no pre-closure charges, no late fees or hidden charges.",
  },
];
export function ExclusiveBenefits() {
  return (
    <>
      <div className="pt-8 space-y-6">
        <h2 className="uppercase font-semibold text-[#A3A3A3] text-lg px-5 tracking-wider">
          EXCLUSIVE BENEFITS
        </h2>
        <div
          id="scrollbar"
          className="snap-x mx-auto snap-mandatory h-60 flex w-[100vw-20px] gap-4 overflow-x-scroll"
        >
          {benefits?.map((benefit) => (
            <ExclusiveCard key={benefit?.title} benefit={benefit} />
          ))}
        </div>
      </div>
    </>
  );
}

const ExclusiveCard = ({ benefit }) => {
  const { icon, title, description } = benefit || {};
  return (
    <>
      <div className="first:ml-5 last:mr-5 h-52 w-48 bg-[#EFE6FF] shadow-shadowY2Px pt-4 pb-2 px-3 space-y-5 rounded-[10px] flex-shrink-0 snap-center">
        <div className="w-12 h-12 rounded-full">
          <img src={icon} className="w-full h-full" alt="" />
        </div>
        <div className="space-y-1">
          <h2 className="text-primary font-bold">{title}</h2>
          <p className="font-semibold text-xs text-[#8567BC]">{description}</p>
        </div>
      </div>
    </>
  );
};

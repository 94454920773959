import femaleAvatar from "../../Assets/female-avater.png";
import maleAvatar from "../../Assets/male-avatar.png";
// import { onMessageListener } from "../../firebase.init";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import getGreeting from "../../utils/getGreeting";
export const TopNavBar = ({ employeeProfile }) => {
  // const [isNotificationHistoryShow, setNotificationHistoryShow] =
  //   useState(false);
  // const [notification, setNotification] = useState({ title: "", body: "" });
  // onMessageListener()
  //   .then((payload) => {
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //     toast((t) => (
  //       <>
  //         <div className="flex flex-col gap-2">
  //           <div className="flex justify-between gap-x-10">
  //             <span>{payload.notification.title}</span>
  //             <button
  //               onClick={() => toast.dismiss(t.id)}
  //               className="text-red-600"
  //             >
  //               &#10006;
  //             </button>
  //           </div>
  //           <span>{payload.notification.body}</span>
  //         </div>
  //       </>
  //     ));
  //   })
  //   .catch((err) => console.log("failed: ", err));

  return (
    <>
      <div className="flex flex-col px-4 bg-primary shadow-shadowY4px">
        <nav className="flex items-center justify-between h-20">
          <AvaterWithUserName employeeProfile={employeeProfile} />

          <ul className="flex items-center">
            <li className="relative border rounded-full h-11 w-11 grid place-content-center border-green-600 bg-green-600">
              <Link to={"tel:+918439309979"}>
                <FaPhoneAlt className="text-white text-lg" />
              </Link>
              {/* <span
                onClick={() => {
                  setNotificationHistoryShow(true);
                  document.body.classList.add("no-scroll");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                >
                  <path
                    d="M13.5 24.75C14.7375 24.75 15.75 23.7375 15.75 22.5H11.25C11.25 23.7375 12.2625 24.75 13.5 24.75ZM20.25 18V12.375C20.25 8.92125 18.4163 6.03 15.1875 5.265V4.5C15.1875 3.56625 14.4338 2.8125 13.5 2.8125C12.5662 2.8125 11.8125 3.56625 11.8125 4.5V5.265C8.595 6.03 6.75 8.91 6.75 12.375V18L4.5 20.25V21.375H22.5V20.25L20.25 18ZM18 19.125H9V12.375C9 9.585 10.6988 7.3125 13.5 7.3125C16.3013 7.3125 18 9.585 18 12.375V19.125Z"
                    fill="white"
                  />
                </svg>
              </span> */}
              {/* {notification?.title && (
                <div className="absolute inline-flex items-center justify-center w-2 h-2 text-xs font-bold bg-red-500  rounded-full -top-0 -right-0"></div>
              )} */}
            </li>
          </ul>
        </nav>
      </div>
      {/* <NotificationHistory
        isNotificationHistoryShow={isNotificationHistoryShow}
        setNotificationHistoryShow={setNotificationHistoryShow}
      /> */}
    </>
  );
};

const AvaterWithUserName = ({ employeeProfile }) => {
  const { avatar, firstName, lastName, gender } = employeeProfile || {};

  // set user avatar if avatar is not found in api call
  const setUserAvatar = (gender) => {
    if (gender?.toLowerCase() === "male") {
      return maleAvatar;
    } else {
      return femaleAvatar;
    }
  };

  return (
    <>
      <div className="text-white flex gap-3">
        <div className="w-11 h-11 rounded-full">
          <img
            src={avatar ? avatar : setUserAvatar(gender)}
            className="w-full h-full rounded-full"
            alt=""
          />
        </div>
        <div className="">
          <h4 className="text-sm">Hello, {getGreeting()}</h4>
          <h2 className="text-lg font-semibold">
            {firstName ? firstName : ""} {lastName ? lastName : ""}
          </h2>
        </div>
      </div>
    </>
  );
};

import React, { createContext, useCallback, useEffect, useState } from "react";
import fetchDataWithRetry from "../utils/fetchDataWithRetry";
export const DataContext = createContext();

const INITIAL_LOAN_PROFILE = {
  employeeRequestedAmount: 0,
  effectiveInterestRate: 0,
  employeeLoanId: null,
  emiDetails: null,
  finalLoanAmount: 0,
  planInitialInfo: null,
};

const INITIAL_LOAN_PAYMENT_INFO = {
  authRequestId: null,
  subscriptionId: null,
};

const DataProvider = ({ children }) => {
  const [metaData, setMetaData] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loanDetails, setLoanDetails] = useState(null);
  const [employeeLoanProfile, setEmployeeLoanProfile] =
    useState(INITIAL_LOAN_PROFILE);
  const [responseError, setResponseError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [authToken, setAuthToken] = useState(null);
  const [loanPaymentInfo, setLoanPaymentInfo] = useState(
    INITIAL_LOAN_PAYMENT_INFO
  );
  const [refetchMetaVersion, setRefetchMetaVersion] = useState(0);

  const fetchMetaData = useCallback(async () => {
    setLoading(true);
    setResponseError(false);
    try {
      const fetchedData = await fetchDataWithRetry("/general/meta");
      setMetaData(fetchedData);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setResponseError(true);
      setLoading(false);
      if (error.message === "unauthorized") {
        setAuthToken(null);
      }
    }
  }, []);

  useEffect(() => {
    fetchMetaData();
  }, [authToken, refetchMetaVersion, fetchMetaData]);

  const dataInfo = {
    userInfo,
    setUserInfo,
    metaData,
    loanDetails,
    setLoanDetails,
    setEmployeeLoanProfile,
    employeeLoanProfile,
    responseError,
    isLoading,
    authToken,
    setAuthToken,
    loanPaymentInfo,
    setLoanPaymentInfo,
    setRefetchMetaVersion,
  };
  return (
    <DataContext.Provider value={dataInfo}>{children}</DataContext.Provider>
  );
};
export default DataProvider;

import instance from "./axiosInstance";

const fetchDataWithRetry = async (url, maxAttempts = 3, delay = 2000) => {
  let attempts = 0;
  let isUnauthorized = false;

  while (attempts < maxAttempts) {
    try {
      const { data } = await instance.get(url);
      return data;
    } catch (error) {
      // console.log(`Attempt ${attempts + 1} failed. Error: ${error.message}`);
      attempts++;
      if (error?.response?.status === 401) {
        isUnauthorized = "unauthorized";
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  if (isUnauthorized) {
    throw new Error("unauthorized");
  } else {
    throw new Error("Max attempts reached. Failed to fetch data.");
  }
};

export default fetchDataWithRetry;

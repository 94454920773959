import { useCallback, useContext, useEffect, useState } from "react";
import { DataContext } from "../Context/DataProvider";

export const useAuthCheck = () => {
  const [authCheck, setAuthCheck] = useState(false);
  const { setAuthToken } = useContext(DataContext);
  const checkAuthStatus = useCallback(() => {
    const auth = localStorage?.getItem("keeperAuthToken");
    return auth;
  }, []);

  useEffect(() => {
    if (checkAuthStatus()) {
      setAuthToken(checkAuthStatus());
    }
    setAuthCheck(true);
  }, [checkAuthStatus, setAuthToken]);
  return authCheck;
};

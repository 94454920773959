import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import ReactPlayer from "react-player";

export function VideoDialog({ isOpen, setIsOpen }) {
  const videoRef = useRef(null);
  const ref = useRef();
  const [fixedDivHeight, setFixedDivHeight] = useState(0);
  const [playPause, setPlayPause] = useState(false);
  const [playerOverlay, setPlayerOverLay] = useState(false);

  useEffect(() => {
    if (ref?.current) {
      setFixedDivHeight(ref?.current?.clientHeight + 50);
    }
  }, [ref, videoRef]);

  useEffect(() => {
    if (!playPause) {
      setPlayerOverLay(true);
    }
    const timeoutId = setTimeout(() => {
      if (playerOverlay && playPause) {
        setPlayerOverLay(false);
        clearTimeout(timeoutId);
      }
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [playerOverlay, playPause]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[9999]"
          onClose={() => setIsOpen(false)}
        >
          <div className="h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full overflow-hidden text-left align-middle transition-all transform">
                <div className="w-full h-screen relative aspect-[9/16]">
                  <ReactPlayer
                    playing={playPause}
                    url={"https://www.youtube.com/watch?v=gTabzU1i-rA"}
                    controls={false}
                    onEnded={() => setPlayPause(false)}
                    width={"100%"}
                    height={"100%"}
                    onClick={() => {
                      setPlayPause((prev) => !prev);
                      setPlayerOverLay((prev) => !prev);
                    }}
                  />
                  <span
                    className="absolute right-3 top-3 border rounded-full grid place-items-center h-10 w-10 bg-black/50 text-white font-bold text-3xl z-[9999]"
                    onClick={() => setIsOpen(false)}
                  >
                    <IoClose />
                  </span>
                  <div
                    className={`absolute hidden top-0 left-0 h-full w-full bg-slate-800/30 opacity-0 transition-all duration-150 rounded  ${
                      playerOverlay
                        ? "opacity-100 transition-all duration-150 z-[999]"
                        : "-z-50"
                    }`}
                    onClick={() => {
                      setPlayPause((prev) => !prev);
                      setPlayerOverLay((prev) => !prev);
                    }}
                  >
                    <span
                      className={`text-white absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] opacity-0 scale-75 text-3xl transition-all duration-300 delay-150 ${
                        playerOverlay
                          ? "opacity-100 !scale-125 transition-all duration-300 delay-150 z-[999]"
                          : "-z-50"
                      }`}
                    >
                      <FaPlay />
                    </span>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

import "./PrimaryButton.css";
export function PrimaryButton({
  action,
  children,
  disabledCondition,
  customStyle,
  buttonType = "submit",
}) {
  // function rippleEffect(event) {
  //   const btn = event.currentTarget;
  //   if (!btn) {
  //     return;
  //   }

  //   const circle = document.createElement("span");
  //   const diameter = Math.max(btn?.clientWidth, btn?.clientHeight);
  //   const radius = diameter / 2;

  //   circle.style.width = circle.style.height = `${diameter}px`;
  //   circle.style.left = `${event.clientX - (btn.offsetLeft + radius)}px`;
  //   circle.style.top = `${event.clientY - (btn.offsetTop + radius)}px`;
  //   circle.classList.add("ripple-effect");

  //   const ripple = btn.getElementsByClassName("ripple-effect")[0];

  //   if (ripple) {
  //     ripple.remove();
  //   }

  //   btn.appendChild(circle);
  // }

  return (
    <>
      <button
        id="primary-button"
        onClick={action}
        type={buttonType}
        className={`relative overflow-hidden cursor-pointer rounded-lg bg-primary p-4 font-semibold capitalize text-white transition duration-200 ease-in-out shadow-shadowY4px w-full place-self-end disabled:bg-gray-400 disabled:text-gray-300 disabled:cursor-not-allowed ${customStyle}`}
        disabled={disabledCondition}
      >
        {children}
      </button>
    </>
  );
}

export function parseObjectKeyText(text) {
  const camelCaseRegex = /([a-z])([A-Z])/;
  const underscoreRegex = /[_]([a-zA-Z])/;
  const isTextIncludeHypen = text.includes("-");

  if (underscoreRegex.test(text)) {
    return text.split("_").join(" ");
  } else if (isTextIncludeHypen) {
    return text.split("-").join(" ");
  } else if (camelCaseRegex.test(text)) {
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space between camelCase words
      .split(/(?=[A-Z])/) // Split by uppercase letters
      .map((word) => word.toLowerCase()) // Convert to lowercase
      .join("");
  } else {
    return text;
  }
}

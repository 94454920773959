export function UserRole({ employeePersonalDetails }) {
  const { organisation, orgLogo, designation } = employeePersonalDetails || {};
  return (
    <>
      <div className="flex items-center gap-3 py-4 text-grayText px-4">
        <div className="w-14 h-14 rounded-full">
          <img src={orgLogo} className="w-full h-full object-contain" alt="" />
        </div>
        <div className="">
          <h2 className="text-lg font-semibold">{designation}</h2>
          <h4 className="text-[15px]">{organisation}</h4>
        </div>
      </div>
    </>
  );
}

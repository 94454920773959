import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { ToWords } from "to-words";
import { PageTitle } from "../../Components/PageTitle/PageTitle";
import { PrimaryButton } from "../../Components/PrimaryButton/PrimaryButton";
import instance from "../../utils/axiosInstance";
import formatNumberWithCommas from "../../utils/formatNumberWithCommas";

function generateDynamicWidth(salary) {
  // dynamic width generate from salary length with for input field
  return salary.length * 25;
}
export default function SalaryDeclaration({ setRefetchMetaVersion }) {
  const [salary, setSalary] = useState("");
  const inputRef = useRef(null);
  const [isFocused, setFocused] = useState(false);
  const toWords = new ToWords();

  const handleSalaryDeclaration = async () => {
    if (!Number(salary)) {
      return toast.error("Please, Input valid value");
    }
    try {
      const res = await instance.post("/general/saveSelfDeclarationData", {
        grossSalary: Number(salary),
      });
      if (res?.status) {
        setRefetchMetaVersion((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // generate dynamic width style for input field
  const dynamicWidth = salary ? generateDynamicWidth(salary) : 150;
  const inputWidth = isFocused ? Math.max(dynamicWidth, 150) : dynamicWidth;
  const style = {
    width: `${inputWidth}px`,
    transition: "all 0.25s",
  };

  // input field will be focused on first render of component
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <>
      <div className="mx-auto relative pb-5">
        <PageTitle isBackBtnShow={false} title={"Salary Declaration"} />
        <div className="px-5 font-sans h-[calc(100vh-144px)] flex flex-col justify-between w-full pt-10">
          <div className="flex flex-col items-center text-center space-y-3">
            <h5 className="font-semibold">Enter your Monthly Gross Pay</h5>
            <div className="flex items-center justify-center gap-3">
              <label
                htmlFor="salary"
                className="text-primary font-bold text-4xl"
              >
                ₹
              </label>
              <input
                id="salary"
                name="salary"
                type="tel"
                ref={inputRef}
                className={` text-primary font-bold text-4xl focus:outline-none focus:border-0 focus:border-b  focus:border-primary `}
                placeholder=""
                value={!isFocused ? formatNumberWithCommas(salary) : salary}
                onChange={(e) => setSalary(e.target.value)}
                onBlur={() => setFocused(false)}
                onFocus={() => setFocused(true)}
                style={style}
                maxLength={"8"}
              />
            </div>
            {salary && (
              <span className="py-2 font-semibold text-primary">
                {toWords.convert(salary)}
              </span>
            )}
            <span className="text-xs">
              Gross Pay is your salary before deductions(Pre-tax Salary)
            </span>
          </div>

          <div className="space-y-5">
            <span className="text-center bg-[#EFE6FF] block rounded-2xl">
              This will be verified from your employer
            </span>
            <PrimaryButton
              action={handleSalaryDeclaration}
              disabledCondition={!salary}
            >
              Proceed
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
}

import { useContext, useEffect, useRef, useState } from "react";
import { FaMinusCircle, FaPhoneAlt, FaPlusCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { DataContext } from "../../Context/DataProvider";
import instance from "../../utils/axiosInstance";
import formatNumberWithCommas from "../../utils/formatNumberWithCommas";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import { HorizontalRangeSlider } from "../RangeSlider/HorizontalRangeSlider/HorizontalRangeSlider";
function setSliderStep(maxValue, value) {
  const SMALL_DIFFERENCE_THRESHOLD = 1000;
  // If the current value is less than 1000 or the difference between the maximum value and the current value is less than 1000,
  // return a step value of 1, otherwise return 1000.
  if (
    value < SMALL_DIFFERENCE_THRESHOLD ||
    maxValue - value < SMALL_DIFFERENCE_THRESHOLD
  ) {
    return 1;
  } else {
    return SMALL_DIFFERENCE_THRESHOLD;
  }
}

function roundedNumber(number) {
  return Math.floor(number / 1000) * 1000;
}

export function GetAmount({
  employeeSalary,
  creditScore,
  setRefetchMetaVersion,
}) {
  const lowCredit = (creditScore >= 300 && creditScore < 600) || false;
  const { setEmployeeLoanProfile } = useContext(DataContext);
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef();
  const navigate = useNavigate();
  const [isAmountUnderLimit, setIsAmountUnderLimit] = useState(true);
  const [maxAmount, setMaxAmount] = useState("");
  const [isScoreReachLimit, setScoreReachLimit] = useState(false);
  const INITIAL_AMOUNT = Math.floor(employeeSalary?.availableLimit * 0.9);
  const [amount, setAmount] = useState(INITIAL_AMOUNT);

  const handleChangeAmount = (value) => {
    if (value > maxAmount) {
      setIsAmountUnderLimit(false);
      setAmount(INITIAL_AMOUNT);
    }

    if (value <= maxAmount) {
      setIsAmountUnderLimit(true);
    }

    if (Number(value)) {
      setAmount(value);
    } else if (value < 1) {
      setAmount(0);
    } else {
      setAmount(INITIAL_AMOUNT);
    }
  };
  const handleIncDecAmount = (condition) => {
    if (
      (amount < 10000 && condition === "dec") ||
      (maxAmount - amount < 10000 && condition === "inc")
    ) {
      if (amount < 10000) {
        setAmount(0);
      } else {
        setAmount(maxAmount);
      }
    } else {
      setAmount((prev) => (condition === "inc" ? prev + 10000 : prev - 10000));
    }
  };

  const handleRegisterLoanAmount = () => {
    if (creditScore === 751) {
      return setScoreReachLimit(true);
    }
    try {
      instance
        .post("/general/registerLoan", {
          loanAmount: amount,
          loanType: lowCredit ? "CB" : "PL",
        })
        .then((res) => {
          if (res?.status === 200) {
            const loanId = res?.data?.loanId;
            const finalLoanAmount = res?.data?.finalLoanAmount;
            setEmployeeLoanProfile((prev) => ({
              ...prev,
              employeeLoanId: loanId,
              finalLoanAmount,
            }));
            setRefetchMetaVersion((prev) => prev + 1);
            navigate("/interest-rate");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // generate dynamic width style for input field
  const dynamicWidth =
    JSON.stringify(amount).length > 2 ? JSON.stringify(amount).length * 25 : 50;
  const inputWidth = inputFocus ? Math.max(dynamicWidth, 50) : dynamicWidth;
  const style = {
    width: `${inputWidth}px`,
  };

  useEffect(() => {
    if (inputFocus) {
      inputRef.current.focus();
    }

    // set employee salary as maximum amount
    if (employeeSalary) {
      setMaxAmount(employeeSalary?.availableLimit);
    }

    // set changable amount
    setEmployeeLoanProfile((prev) => ({
      ...prev,
      employeeRequestedAmount: amount,
    }));
  }, [inputFocus, employeeSalary, amount, setEmployeeLoanProfile]);

  return (
    <>
      <div className="px-5 pt-5 space-y-7">
        <div>
          <div className="text-center flex items-center justify-center gap-x-7">
            <button
              className="text-primary text-[1.35rem]  disabled:text-primary/70"
              onClick={() => handleIncDecAmount("dec")}
              disabled={amount <= 0}
            >
              <FaMinusCircle />
            </button>
            {!inputFocus ? (
              <span
                onClick={() => {
                  setInputFocus(true);
                }}
                className="font-bold text-[2.5rem] text-secondary"
              >
                ₹
                {formatNumberWithCommas(
                  INITIAL_AMOUNT === amount ? roundedNumber(amount) : amount
                )}
              </span>
            ) : undefined}
            {inputFocus ? (
              <input
                ref={inputRef}
                type="tel"
                defaultValue={amount}
                className="text-left focus:border-0 focus:outline-none text-secondary font-bold text-[2.5rem]"
                onBlur={(e) => {
                  setInputFocus(false);
                }}
                maxLength={8}
                onChange={(e) => handleChangeAmount(e.target.value)}
                style={style}
              />
            ) : undefined}
            <button
              className="text-primary text-[1.35rem] disabled:text-primary/70"
              onClick={() => handleIncDecAmount("inc")}
              disabled={amount >= maxAmount}
            >
              <FaPlusCircle />
            </button>
          </div>

          <div className="my-3">
            <HorizontalRangeSlider
              max={maxAmount}
              min={0}
              value={amount}
              setValue={handleChangeAmount}
              step={setSliderStep(maxAmount, amount)}
            />
          </div>
          <div className="flex justify-between mb-1">
            <div className="w-1/2">
              <div className="">
                <span className="text-right text-secondary font-semibold inline-block text-lg">
                  ₹{formatNumberWithCommas(amount)}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-end w-1/2">
              <div className="text-right">
                <span className="text-right text-secondary font-semibold inline-block text-lg">
                  ₹{formatNumberWithCommas(maxAmount)}
                </span>
              </div>
            </div>
          </div>
          <span
            className={`text-red-500 mt-1 text-center opacity-0 transition-all duration-150 ${
              isAmountUnderLimit ? "hidden" : "block"
            }`}
            style={{ opacity: !isAmountUnderLimit && 1 }}
          >
            Amount should be under ₹{formatNumberWithCommas(maxAmount)}
          </span>
        </div>
        <div className="space-y-5">
          <PrimaryButton
            action={handleRegisterLoanAmount}
            customStyle={"!rounded-4xl"}
            disabledCondition={!isAmountUnderLimit || amount == 0}
          >
            {!lowCredit ? "Check EMI" : "Improve Credit Score"}
          </PrimaryButton>
          {isScoreReachLimit && (
            <span className="block text-center text-sm text-primary">
              We're fetching your CIBIL score. Try again in some time.
            </span>
          )}

          <PrimaryButton customStyle={"!bg-[#529a60] !rounded-full"}>
            <Link
              to={"tel:+918439309979"}
              className="flex justify-center items-center gap-x-2"
            >
              <FaPhoneAlt className="text-lg" />{" "}
              <span>Call our EMI Expert</span>
            </Link>
          </PrimaryButton>
        </div>
      </div>
    </>
  );
}

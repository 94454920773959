import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { BackNavigation } from "../../Components/BackNavigation/BackNavigation";
import { PrimaryButton } from "../../Components/PrimaryButton/PrimaryButton";
import instance from "../../utils/axiosInstance";
import { parseObjectKeyText } from "../../utils/parseObjectKeyText";

const locationDataFields = [
  "addressLine1",
  "addressLine2",
  "city",
  "state",
  "country",
  "zipCode",
];

export default function MoreRequiredField({
  missingDataType,
  missingDataFieldsName,
  setCurrentStep,
  setRefetchMetaVersion,
}) {
  const [submitting, setSubmitting] = useState("initial");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (payload) => {
    const { address, ...restValus } = payload || {};
    const location = {
      workAddress: { ...address, addressType: "Work" },
      permanentAddress: { ...address, addressType: "permanent" },
      presentAddress: { ...address, addressType: "present" },
    };
    const bodyData = missingDataFieldsName?.includes("location")
      ? { location, ...restValus }
      : { ...restValus };

    setSubmitting("loading");
    try {
      const { data } = await instance.post(
        missingDataType === "softPull"
          ? `general/fillSoftPullMissingData`
          : `general/fillNewLoanMissingData`,
        bodyData
      );
      if (data?.status) {
        setSubmitting("success");
        if (missingDataType === "softPull") {
          setRefetchMetaVersion((prev) => prev + 1);
        } else {
          setCurrentStep((prev) => prev + 1);
        }
      }
    } catch (error) {
      setSubmitting("error");
      console.log(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={` relative overflow-y-auto pb-48 ${
        missingDataType === "softPull" ? "px-5 py-10 pt-20" : ""
      }`}
    >
      {missingDataType === "softPull" ? <BackNavigation /> : undefined}
      <div className="space-y-10">
        {missingDataType === "softPull" ? (
          <h1 className="font-medium text-xl">
            A few more detials are required:
          </h1>
        ) : undefined}
        <div className="space-y-5">
          {missingDataFieldsName?.includes("location")
            ? locationDataFields?.map((fieldName, i) => (
                <React.Fragment key={fieldName + i}>
                  <InputField
                    fieldName={fieldName}
                    register={register}
                    errors={errors}
                    fieldType={"address"}
                  />
                </React.Fragment>
              ))
            : undefined}
          {missingDataFieldsName?.map((fieldName, i) => (
            <React.Fragment key={fieldName + i}>
              {fieldName !== "location" ? (
                <InputField
                  fieldName={fieldName}
                  register={register}
                  errors={errors}
                />
              ) : undefined}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="fixed bottom-0 w-full left-[50%] -translate-x-[50%] px-5 bg-white pb-20 pt-10">
        <PrimaryButton disabledCondition={submitting === "loading"}>
          {submitting === "loading" ? "Submitting..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
}

const InputField = ({ fieldName, register, errors, fieldType }) => {
  function getFieldType(fieldName) {
    const isDob = fieldName?.toLowerCase()?.includes("dateofbirth");
    if (isDob) {
      return "date";
    } else {
      return "text";
    }
  }
  return (
    <>
      <div className="space-y-2">
        <label className="capitalize">
          {fieldName?.toLowerCase()?.includes("dateofbirth")
            ? "Date of birth"
            : parseObjectKeyText(fieldName)}
        </label>
        <input
          type={`${getFieldType(fieldName)}`}
          className={`w-full py-3 border border-slate-500 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow`}
          {...register(fieldType ? `${fieldType}.${fieldName}` : fieldName, {
            required: `${parseObjectKeyText(fieldName)} is required`,
          })}
        />
        {Object.keys(errors)?.length ? (
          <span className="text-red-600 text-sm block pt-1">
            {errors[fieldName]?.message}
          </span>
        ) : undefined}
      </div>
    </>
  );
};

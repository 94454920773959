import React, { useContext, useState } from "react";
import csImg from "../../Assets/cs-1.png";
import keyImg from "../../Assets/key-1.png";
import manageImg from "../../Assets/manage-1.png";
import spamImg from "../../Assets/spam-1.png";
import { DataContext } from "../../Context/DataProvider";
import { GetAmount } from "../GetAmount/GetAmount";
import { VideoDialog } from "../VideoDialog/VideoDialog";

export default function ImproveCreditScore() {
  const { metaData, setRefetchMetaVersion } = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(false);
  const creditScore = metaData?.employeeProfile?.creditScore;
  return (
    <>
      <div className="py-5 pb-0 space-y-5">
        <h1 className="text-center text-red-500 font-semibold text-xl px-5">
          Your credit score is low: {creditScore}
        </h1>
        <GetAmount
          employeeSalary={metaData?.employeeSalary}
          creditScore={creditScore}
          setRefetchMetaVersion={setRefetchMetaVersion}
        />
        <div className="px-5 space-y-7">
          {/* <PrimaryButton
            action={() => setIsOpen(true)}
            customStyle={"!rounded-full bg-primary/25 !text-primary"}
          >
            Watch Video
          </PrimaryButton> */}
          <CreditManagement />
        </div>
      </div>
      <VideoDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

const creditManagementInfo = [
  { title: "Your credit score is low", image: csImg },
  { title: "Learn to manage creditw", image: manageImg },
  { title: "No Ads, No Spam", image: spamImg },
  { title: "Totally Safe, Totally Secure", image: keyImg },
];
const CreditManagement = () => {
  return (
    <>
      <div
        id="scrollbar"
        className="snap-x mx-auto snap-mandatory flex w-[100vw-20px] gap-4 overflow-x-scroll"
      >
        {creditManagementInfo?.map(({ title, image }, i) => (
          <div
            key={title}
            className="w-44 flex-shrink-0 snap-center flex flex-col justify-start items-center h-36 text-center"
          >
            <img src={image} alt="img" className="object-contain" />
            <span className="text-sm -mt-2 w-[75%]">{title}</span>
          </div>
        ))}
      </div>
    </>
  );
};

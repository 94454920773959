import { useEffect, useRef, useState } from "react";
let activeIndex = 0;
export function OtpInputField({ otp, setOtp }) {
  const [activeField, setActiveField] = useState(0);
  const inputRef = useRef();

  // get input value and update active field for next input focus
  const handleOnChange = (e) => {
    const value = e.target.value;
    const userOtp = [...otp];
    userOtp[activeIndex] = value.substring(value.length - 1);
    if (!value) setActiveField(activeIndex - 1);
    else setActiveField(activeIndex + 1);
    if (userOtp.join("")?.length === 6) {
      inputRef?.current?.blur();
    }
    setOtp(userOtp);
  };

  // clear inputted otp code one by one
  const handleOnkeyDown = (e, index) => {
    activeIndex = index;
    if (e.key === "Backspace") setActiveField(activeIndex - 1);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeField]);
  return (
    <>
      {otp?.map((_, index) => (
        <div key={index}>
          <input
            type="tel"
            className="w-12 h-14 border rounded-lg bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition"
            ref={index === activeField ? inputRef : null}
            value={otp[index]}
            onChange={handleOnChange}
            onKeyDown={(e) => handleOnkeyDown(e, index)}
          />
          {index === otp.length - 1 ? null : (
            <span className="w-2 py-0.5 bg-gray-400" />
          )}
        </div>
      ))}
    </>
  );
}

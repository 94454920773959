import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import Loader from "./Components/Loader/Loader";
import { router } from "./Routes/Routes";
import { useAuthCheck } from "./hooks/useAuthCheck";
import checkVersion from "./utils/versionChecker";

function App() {
  const authCheck = useAuthCheck();

  useEffect(() => {
    checkVersion();
  }, []);
  return (
    <>
      {!authCheck ? (
        <Loader message="Checking Authentication" />
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
}

export default App;

import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import App from "./App";
import AuthProvider from "./Context/AuthProvider";
import DataProvider from "./Context/DataProvider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <DataProvider>
        <Toaster />
        <App />
      </DataProvider>
    </AuthProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
// swDev();
reportWebVitals();

import React from "react";
import { FaCheck } from "react-icons/fa";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const texts = ["No Pre-closure Charges", "No Lock-in"];
export default function FreedomPanel() {
  return (
    <div className="mt-5">
      <Swiper
        slidesPerView={1}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {texts?.map((text, i) => (
          <SwiperSlide key={i} className="text-center">
            <div className="text-lg font-semibold text-secondary flex justify-center items-center gap-x-2">
              <FaCheck className="text-[#529a60]" />
              {text}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

import { getMessaging, getToken } from "firebase/messaging";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import ImproveCreditScore from "../../Components/CreditScoreLowState/ImproveCreditScore";
import { ExclusiveBenefits } from "../../Components/ExclusiveBenefits/ExclusiveBenefits";
import FreedomPanel from "../../Components/FreedomPanel/FreedomPanel";
import { GetAmount } from "../../Components/GetAmount/GetAmount";
import LeavesInfo from "../../Components/LeavesInfo/LeavesInfo";
import { LoanStatus } from "../../Components/LoanStatus";
import { QuickActions } from "../../Components/QuickActions/QuickActions";
import { TopNavBar } from "../../Components/TopNavBar/TopNavBar";
import { UserRole } from "../../Components/UserRole/UserRole";
import { DataContext } from "../../Context/DataProvider";
import app, { onMessageListener } from "../../firebase.init";
import axiosInstance from "../../utils/axiosInstance";
const messaging = getMessaging(app);

export const Home = () => {
  const { metaData, setRefetchMetaVersion } = useContext(DataContext);
  const [loanStatus, setLoanStatus] = useState("started");
  const { creditScore, contactNumber } = metaData?.employeeProfile || {};
  const lowCredit = (creditScore >= 300 && creditScore < 600) || false;
  // store device token
  useEffect(() => {
    // check if token is already available in session
    const tokenAvailable = sessionStorage.getItem("deviceToken");
    if (tokenAvailable) {
      return;
    }
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_vapidKey,
    }).then((currentToken) => {
      if (currentToken) {
        axiosInstance
          .post("/general/storeDeviceToken", {
            fcmToken: currentToken,
          })
          .then((res) => {
            if (res?.data?.status === "success") {
              sessionStorage.setItem("deviceToken", true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log("Can not get token");
      }
    });
  }, []);

  useEffect(() => {
    const status = metaData?.employeeLoans?.loanStatus?.toLowerCase();

    setLoanStatus(status ? status : "started");

    // push notification in live app
    onMessageListener()
      .then((payload) => {
        const notification = payload.notification;
        toast(notification.body);
      })
      .catch((err) => console.log("failed: ", err));
  }, [metaData?.employeeLoans]);

  // checking mutualFundEligibility null value
  // const isMutualFundEligible = Object.keys(
  //   metaData?.mutualFundEligibility
  // )?.every((key) => metaData?.mutualFundEligibility[key] === null);
  // const isFlowDrawerOpen = sessionStorage.getItem("flow_drawer_close");
  return (
    <>
      <div className="relative">
        <div className="fixed top-0 w-full z-50">
          <TopNavBar employeeProfile={metaData?.employeeProfile} />
        </div>
        <div className="pt-20">
          <UserRole
            employeePersonalDetails={metaData?.employeePersonalDetails}
          />

          {loanStatus === "started" || !loanStatus ? (
            !lowCredit ? (
              <div>
                <GetAmount
                  employeeSalary={metaData?.employeeSalary}
                  creditScore={creditScore}
                  setRefetchMetaVersion={setRefetchMetaVersion}
                />
                <FreedomPanel />
                <LeavesInfo metaData={metaData} />
                {/* <MutualFundsInfo metaData={metaData} /> */}
                <ExclusiveBenefits />
              </div>
            ) : (
              <ImproveCreditScore />
            )
          ) : (
            <LoanStatus loanStatus={loanStatus} />
          )}

          <QuickActions />
        </div>
      </div>
      {/* {isMutualFundEligible && !isFlowDrawerOpen ? (
        <LAMPFlow contactNumber={contactNumber} />
      ) : undefined} */}
    </>
  );
};

import { React, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import checkingLottie from "../../Assets/lottie-animation/124311-checking-3d-as-bayteq.json";
import fetchingLottie from "../../Assets/lottie-animation/fetching_data_animation.json";
import { LottieWrapper } from "../../Components/LottieWrapper/LottieWrapper";
import { OtpInputField } from "../../Components/OtpInputField/OtpInputField";
import { ResendOtp } from "../../Components/ResendOtp/ResendOtp";
import { DataContext } from "../../Context/DataProvider";
import instance from "../../utils/axiosInstance";

const LOTTIE_ONE_DURATION = 3500;
const LOTTIE_TWO_DURATION = 3500;
export const VerifyOtp = () => {
  const { userInfo, setUserInfo, setAuthToken } = useContext(DataContext);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [lottieOne, setLottieOne] = useState(true);
  const [lottieTwo, setLottieTwo] = useState(false);
  const navigate = useNavigate();

  const isOtpInputted = otp.join("")?.length === 6;
  // send otp data for verification
  const verifyOtp = (otpData) => {
    return instance.post("/auth/verifyOtp", otpData);
  };

  // animation showing condition
  const handleLottieRef = (auth) => {
    setLottieOne(false);
    setLottieTwo(true);
    setTimeout(() => {
      setAuthToken(auth);
      localStorage.setItem("keeperAuthToken", JSON.stringify(auth));
      navigate("/pan-verification");
    }, LOTTIE_TWO_DURATION);
  };

  useEffect(() => {
    if (isOtpInputted) {
      const otpData = {
        otp: otp.join(""),
        otpSessionId: userInfo?.sessionId,
        contactNumber: userInfo?.phone,
      };

      const startTime = new Date();
      try {
        verifyOtp(otpData)
          .then((res) => {
            if (res?.status === 200) {
              const auth = res?.data?.jwt;
              const endTime = new Date();
              const responseTime = endTime - startTime;
              if (responseTime < LOTTIE_ONE_DURATION) {
                const duration = LOTTIE_ONE_DURATION - responseTime;
                setTimeout(() => {
                  handleLottieRef(auth);
                }, duration);
              } else {
                setTimeout(() => {
                  handleLottieRef(auth);
                }, responseTime);
              }
            }
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              const errorCode = error?.response?.data?.errorCode;
              if (errorCode === "WO") {
                setOtp(new Array(6).fill(""));
                return toast.error("OTP verification failed!");
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [isOtpInputted, userInfo, otp]);

  useEffect(() => {
    if (!userInfo?.sessionId) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  return (
    <>
      <div className="flex flex-col min-h-screen w-full content-center justify-between py-10 pt-20 px-5 bg-white">
        <span
          onClick={() => {
            setUserInfo(null);
            navigate("/login");
          }}
          className="absolute top-5 left-5 z-50 h-[30px] w-[30px] cursor-pointer"
        >
          <AiOutlineLeft className="h-6 w-6" />
        </span>
        <div>
          <h1 className="text-2xl font-normal">
            Enter 6-digit code to verify your number
          </h1>
          <span className="text-gray-400 text-sm">
            And take a walk towards prosperity
          </span>
          <div className="mt-10 mb-2">
            <div className="flex justify-center gap-3">
              <OtpInputField otp={otp} setOtp={setOtp} />
            </div>
          </div>
          <ResendOtp setOtp={setOtp} />
          {/* {err && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg relative"
              role="alert"
            >
              <strong className="font-bold">Invalid input!</strong>
            </div>
          )} */}
        </div>
      </div>
      {isOtpInputted && (
        <LottieWrapper
          animationData={checkingLottie}
          timeout={LOTTIE_ONE_DURATION}
          customWidth="75%"
          speed={1}
          text={"Checking your eligibility..."}
          customColor={"#FB6D7B"}
          textPosition={"-bottom-[25%]"}
          isShowing={lottieOne}
        />
      )}
      {!lottieOne && lottieTwo && (
        <LottieWrapper
          animationData={fetchingLottie}
          timeout={LOTTIE_TWO_DURATION}
          speed={0.5}
          text={"Fetching Data from Employer"}
          textPosition={"bottom-[25%]"}
          isShowing={lottieTwo}
        />
      )}
    </>
  );
};

import warningImg from "../../Assets/warning-credit-score.png";
import { PageTitle } from "../../Components/PageTitle/PageTitle";
import { PrimaryButton } from "../../Components/PrimaryButton/PrimaryButton";
export function PanEligibility({ errorCode, setErrorCode }) {
  return (
    <>
      <PageTitle
        className={"absolute w-full"}
        title={"Credit Score Verification"}
        isNavigate={false}
        setNavigateCondition={setErrorCode}
        state={null}
      />
      <div className="h-screen grid place-items-center px-5">
        {errorCode === "SPF" ? (
          <>
            <div className="text-center px-5 space-y-3">
              <h4>
                Some error occured fetching your credit score. Kindly contact
                Keeper Support.
              </h4>
              <div>
                <h5>
                  <span className="font-semibold">Contact Number:</span>
                  <span> 8439309979</span>
                </h5>
                <h5>
                  <span className="font-semibold">Email:</span>
                  <span> hello@keeper.co.in</span>
                </h5>
              </div>
              <PrimaryButton
                action={() => window.location.reload()}
                customStyle={"!py-4 !px-1 !mt-5"}
              >
                Try Again
              </PrimaryButton>
            </div>
          </>
        ) : (
          <div className="space-y-5">
            <img src={warningImg} alt="" />
            <h1 className="text-secondary font-bold text-lg text-center">
              Your application could not clear our lender partner, i2i Funding
              internal loan approval policy. You may please try after 90 days to
              check your eligibility.
            </h1>
          </div>
        )}
      </div>
    </>
  );
}
